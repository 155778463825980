import ArrowDisabler from './utils/ArrowDisabler.Glide.js';
import 'alpinejs';

// App main
const main = async () => {

    return null;
};

// Execute async function
main().then( (root) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
